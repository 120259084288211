import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"

export default createStore({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        })
    ],
    state: {
        user: {},
        menus: [],
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setMenus(state, menus) {
            state.menus = menus;
        },
    },
    actions: {},
    modules: {},
});