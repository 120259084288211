<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-size: 16px;
}

.el-range-separator {
  height: auto !important;
}

pre{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

/* 滚动条修改 */
.el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    height: 120px;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
  }
}

 .tangram-suggestion-main {
        z-index: 999999;
  }

</style>
