export const cacheToken = (data) => {
  sessionStorage.setItem('expiresAt', data.expiresAt || '')
  sessionStorage.setItem('invalidData', data.invalidData || '')
  sessionStorage.setItem('issuedAt', data.issuedAt || '')
  sessionStorage.setItem('token', data.token || '')
}

export const getToken = () => {
  return sessionStorage.getItem('token')
}

export const isLogin = () => {
  return sessionStorage.getItem('token') && true
}

export const isExpires = () => {
  let expiresAt = sessionStorage.getItem('expiresAt')
  if (expiresAt) {
    return new Date().getTime() >= new Date(expiresAt).getTime()
  }
  return true
}

export const isInvalid = () => {
  let invalidData = sessionStorage.getItem('invalidData')
  if (invalidData) {
    return new Date().getTime() >= new Date(invalidData).getTime()
  }
  return true
}

export const clear = () => {
  sessionStorage.clear()
}
