<template>
  <div class="info-container">

    <el-dialog
        v-model="visible"
        title="注意事项"
        width="650px"
        :before-close="handleClose">


      <div class="info-content">

        云平台注意事项说明
        <br/>
        <br/>
        欢迎各位使用此功能，因此功能涉及和终端数据交互，会涉及到一定的权限问题，请您认真阅读如下信息，当您遇到如下问题时，请按照如下规定进行操作：
        1、在获取终端相关功能（终端配置、远程诊断、远程控制、FOTA）时，请先读取相关参数并进行确认合理性；如发现数据不合理及时反馈给技术开发人员，有开发人员进行解答；
        2、在设置终端相关功能（终端配置、远程诊断、远程控制、FOTA）时，务必要确保终端处于非行驶状态（GPS车速为0），方可进行下发设置指令，并且输入登录密码
        ，方可下发成功，切记不可盲目下发设置指令，否则可能会引起不必要的隐患（比如车辆停车、电池无法输出电源）；
        3、关于设置终端参数功能，对于要修改的参数必须要通过技术人员正式途径（正式邮件）获取，不可以随意修改，否则会引起不必要的隐患；
        4、后续如有其他使用规范，均由由技术中心邮件告知并宣讲。
        以上信息请确认知悉，感谢使用！

      </div>


      <template #footer>
        <div class="dialog-footer">
          <el-button size="medium" type="primary" @click="visible = false">我已知晓</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {computed} from 'vue'

export default {
  props: {
    modelValue: Boolean
  },
  setup (props, context) {

    const handleClose = () => {
      context.emit('close')
    }

    const visible = computed({
      get: () => {
        return props.modelValue
      },
      set: (val) => {
        handleClose()
        return val
      }
    })

    return {
      visible,
      handleClose,
    }
  }
}
</script>
<style lang="scss" scoped>
.info-container {
  .dialog-footer {
    display: flex;
    justify-content: space-around;
  }

  .info-content {
    line-height: 25px;
  }
}
</style>
