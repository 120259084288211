import api from "!../node_modules/_style-loader@2.0.0@style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/_css-loader@5.2.7@css-loader/dist/cjs.js!../node_modules/_vue-loader@16.8.3@vue-loader/dist/stylePostLoader.js!../node_modules/_sass-loader@11.1.1@sass-loader/dist/cjs.js!../node_modules/_postcss-loader@4.3.0@postcss-loader/dist/cjs.js!../node_modules/_vue-loader@16.8.3@vue-loader/dist/index.js??ruleSet[1].rules[14].use[0]!./App.vue?vue&type=style&index=0&id=7ba5bd90&lang=scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};