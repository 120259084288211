import {
  create,
  getSelf,
  list,
  patch,
  resetPassword,
  refreshToken,
  checkPassword,
  menuList,
  patchPassword,
  menuAndDevice
} from "../graphql/admin.graphql";

import { mutate, query } from "../utils/graphql";
import { post } from "../utils/request";

export function adminLogin(username, password) {
  return post(`auth/login?username=${username}&password=${password}`);
}

export function adminList(limit, offset, realName) {
  return query(list, { limit, offset, realName });
}

export function adminPermissionList(){
  return query(menuList);
}

export function adminCreate(admin) {
  return mutate(create, { admin: admin });
}

export function adminPatch(admin) {
  return mutate(patch, { admin: admin });
}

export function adminResetPassword(admin) {
  return mutate(resetPassword, { admin: admin });
}

export function adminPatchPassword(admin) {
  return mutate(patchPassword, { ...admin });
}

export function adminGetSelf(admin) {
  return query(getSelf);
}

export function adminRefreshToken() {
  return query(refreshToken);
}

export function adminCheckPassword(raw) {
  return query(checkPassword, { raw });
}

export function adminUserMenuAndDevice(id) {
  return query(menuAndDevice, {id});
}
