import moment from "moment";

let env = process.env.NODE_ENV;
let apiEnv = process.env.API_ENV;

let url = "https://api.test.battery.hoopluz.com";

if (apiEnv === "debug") {
  url = '//127.0.0.1:9092'
}

if (env === "production") {
  url = "https://api.eureka-ecloud.com";
}


export function getBaseUrl() {
  return url;
}

export function filterNullValue(body) {
  if (typeof body === "object") {
    let result = {};
    Object.keys(body).forEach((key) => {
      if (`${body[key]}` === 0 || `${body[key]}` === "false" || `${body[key]}` === "0" || body[key]) {
        result[key] = body[key];
      }
    });
    return result;
  }
  return body;
}

export function toRangTime(data) {
  if (data && data instanceof Array && data.length === 2) {
    return {
      startTime: moment(data[0]).format("yyyy-MM-DD 00:00:00"),
      endTime: moment(data[1]).format("yyyy-MM-DD 23:59:59")
    }
  }
  return {}
}

export function copyToQuery(data, ...executions) {
  let query = {}
  Object.keys(data).forEach(key => {
    if (executions.indexOf(key) === -1 && data[key]) {
      query[key] = data[key]
    }
  });
  return query;
}
