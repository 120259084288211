import Axios from "axios";
import {getBaseUrl} from "./common";
import {ElNotification} from "element-plus";
import {saveAs} from "file-saver";
import {getToken} from "@/utils/cache";
import moment from "moment";

const axios = Axios.create({
  baseURL: getBaseUrl(),
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(error.response);
    if (error.response && error.response.data && error.response.data.msg) {
      ElNotification({
        title: "提示",
        message: error.response.data.msg,
      });
    }
    return Promise.reject(error);
  }
);

export function post(url, body) {
  return axios.post(url, body);
}

export async function exportExcel(url, body, fileName) {
  const token = getToken("token");
  url = getBaseUrl() + url + `?token=${token}`;
  Object.keys(body).forEach(key => {
    if (body[key]) {
      url += `&&${key}=${body[key]}`;
    }
  })
  await saveAs(url, fileName);
}

export async function exportTimestampExcel(url, body, fileName) {
  await exportExcel(url, body, `${fileName}导出数据${moment().format("yyyyMMDDHHmmss")}.xlsx`)
}
