import store from "../store"

const ROLE_ADMIN = 0;
const ROLE_SUPER = 1;
const ROLE_COMPANY = 2;

let env = process.env.NODE_ENV;

const install = (Vue) => {
  Vue.directive("hide-admin", {
    mounted(el) {
      hideByRole(el, ROLE_ADMIN)
    }
  })

  Vue.directive("hide-company", {
    mounted(el) {
      hideByRole(el, ROLE_COMPANY)
    }
  })

  Vue.directive("show-super", {
    mounted(el) {
      showByRole(el, ROLE_SUPER)
    }
  })

  Vue.directive("show-exported", {
    mounted(el) {
      showByRoleAndExported(el)
    }
  })

  Vue.directive("hide-prod", {
    mounted(el) {
      if (env === 'production') {
        hideElement(el)
      }
    }
  })
}

function showByRoleAndExported(el) {
  const user = store.state.user;
  if (user.role === ROLE_ADMIN || user.role === ROLE_SUPER) {
    return;
  }
  if (user.exported) {
    return;
  }
  hideElement(el)
}

function hideByRole(el, role) {
  const user = store.state.user;
  if (!user || user.role === role) {
    hideElement(el)
  }
}

function showByRole(el, role) {
  const user = store.state.user;
  if (!user || user.role !== role) {
    hideElement(el)
  }
}

function hideElement(el) {
  if (el) {
    el.parentNode.removeChild(el)
  }
}

export default install;
