import {ApolloClient} from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {ApolloLink, split} from "apollo-link";
import {InMemoryCache} from "apollo-cache-inmemory";
import {onError} from "apollo-link-error";
import {ElMessage, ElNotification} from "element-plus";
import {cacheToken, getToken} from "./cache";
import {getBaseUrl, filterNullValue} from "./common";
import WebSocketLink from "./webSocketLink";
import {getMainDefinition} from "apollo-utilities";
import {testSubscription} from "../graphql/test.graphql";

let enableWebSocket = false;

const httpLink = new HttpLink({
  uri: `${getBaseUrl()}/graphql`,
});

const wsLink = new WebSocketLink({
  url: `ws://${getBaseUrl()}/graphql`,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  let headers = {};
  let token = getToken();
  if (token) {
    headers["Authorization"] = token;
  }
  operation.setContext({
    headers: {...headers, "Access-Control-Allow-Origin": "*"},
  });
  return forward(operation).map((response) => {
    if (response.data && response.data.refreshToken) {
      cacheToken(response.data.refreshToken);
    }
    return response;
  });
});

const errorLink = onError(({networkError, response}) => {
  if (networkError) {
    ElMessage.error("网络错误");
    return;
  }
  if (response && response.errors) {
    let message = response.errors.map((error) => error.message).join("\n");
    ElNotification({
      title: "提示",
      message: message,
    });
  }
});

let link = errorLink.concat(middlewareLink).concat(httpLink);

if (enableWebSocket) {
  link = split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    link
  );
}

const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export const query = (query, variables) => {
  let body = {};
  if (variables) {
    Object.keys(variables).forEach((key) => {
      if (variables[key]) {
        body[key] = filterNullValue(variables[key]);
      }
    });
  }
  return apolloClient.query({query: query, variables: body});
};

export const mutate = (mutate, variables) => {
  return apolloClient.mutate({mutation: mutate, variables: variables});
};


if (enableWebSocket) {
  // this is test code , you can copy it
  apolloClient.subscribe({query: testSubscription}).subscribe({
    complete(res) {
    },
    error(errorValue) {
    },
    next(value) {
      ElNotification.info({message: value.data.testSubscription});
    },
  });
}
