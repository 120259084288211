import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import moment from 'moment'
import * as Icons from '@element-plus/icons'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import Info from './components/info'
import SelectDisableInput from './components/SelectDisableInput'
import Directive from "./utils/directive"

import vue3Clipboard from 'vue3.0-clipboard'

moment.locale('zh-cn')

let app = createApp(App)
app.component('Info', Info)
app.component('SelectDisableInput', SelectDisableInput)
app.use(store)
app.use(Directive)
app.use(router)
app.use(vue3Clipboard)
app.provide('moment', moment)
app.use(ElementPlus, {locale})
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key], {locale})
})
app.mount('#app')

