import {ApolloLink, Observable} from 'apollo-link'
import {createClient} from 'graphql-ws'
import {print} from 'graphql'

export default class WebSocketLink extends ApolloLink {

  client

  constructor (options) {
    super()
    this.client = createClient(options)
  }

  request (operation) {
    let query = {
      ...operation,
      query: print(operation.query)
    }
    return new Observable((sink) => {
      return this.client.subscribe(
        query, {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: err => this.onError(err, sink),
        },
      )
    })
  }

  onError (err, sink) {
    if (Array.isArray(err)) {
      return sink.error(new Error(err.map(({message}) => message).join(', ')))
    }
    if (err instanceof CloseEvent) {
      return sink.error(
        new Error(`Socket closed with event ${err.code} ${err.reason || ''}`))
    }
    return sink.error(err)
  }
}
