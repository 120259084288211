import {createRouter, createWebHashHistory} from "vue-router";

import {clear, isExpires, isInvalid, isLogin} from "../utils/cache.js";
import {ElMessage} from "element-plus";
import {adminRefreshToken} from "../api/admin";
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import store from "../store";

NProgress.configure({showSpinner: false});

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      auth: false,
      role: false,
    },
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/main/home.vue"),
    meta: {
      role: false
    },
    children: [
      {
        path: "/about",
        name: "About",
        component: () => import("../views/about/about.vue"),
        meta: {
          role: false,
        },
      },
      {
        path: "/upgrade",
        name: "upgrade",
        component: () => import("../views/fota/upgrade.vue"),
      },
      {
        path: "/upgrade/detail/:packageId",
        name: "UpgradeDetail",
        component: () => import("../views/fota/upgradeDetail.vue"),
        meta: {
          role: false,
        },
      },
      {
        path: "/device",
        name: "Device",
        component: () => import("../views/device/device.vue"),
      },
      {
        path: "/device/detail/:deviceId",
        name: "DeviceDetail",
        component: () => import("../views/device/deviceDetail.vue"),
        meta: {
          role: false,
        },
      },
      {
        path: "/device/track/:deviceId",
        name: "HistoricalTrack",
        component: () => import("../views/device/historicalTrack.vue"),
        meta: {
          role: false,
        },
      },
      {
        path: "/device-status",
        name: "DeviceStatus",
        component: () => import("../views/device/deviceStatus.vue"),
      },
      {
        path: "/device-status/history/:deviceId",
        name: "DeviceStatusHistory",
        component: () => import("../views/device/deviceStatusHistory.vue"),
        meta: {
          role: false,
        },
      },
      {
        path: "/device-control",
        name: "DeviceControl",
        component: () => import("../views/control/control.vue"),
      },
      {
        path: "/config",
        name: "DeviceConfig",
        component: () => import("../views/config/config.vue"),
      },
      {
        path: "/diagnosis",
        name: "DeviceDiagnosis",
        component: () => import("../views/diagnosis/diagnosis"),
      },
      {
        path: "/admin",
        name: "Admin",
        component: () => import("../views/user/admin.vue"),
        meta: {
          role: false,
        },
      },
      {
        path: "/user",
        name: "User",
        component: () => import("../views/user/user.vue"),
      },
    ],
  },
  {
    path: "/401",
    name: "401",
    meta: {
      auth: false,
      role: false
    },
    component: () => import("../views/error/401.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    meta: {
      auth: false,
      role: false
    },
    component: () => import("../views/error/404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  let auth = true;
  let role = true;

  if (to.meta && to.meta.hasOwnProperty("auth")) {
    auth = to.meta.auth;
  }

  if (to.meta && to.meta.hasOwnProperty("role")) {
    role = to.meta.role;
  }

  if (auth && !await checkAuth(next)) {
    toLogin(next);
    return;
  }

  if (role && !checkRole(to)) {
    to401(next)
    return;
  }

  next();

});

router.afterEach(() => {
  NProgress.done();
});

function checkRole(to) {
  let menu = (store.state.menus || []).find(menu => menu.path === to.path);
  return menu && menu.id;
}

async function checkAuth() {
  if (!isLogin()) {
    console.log("no login");
    return false;
  }

  if (isInvalid()) {
    console.log("token Invalid");
    ElMessage.warning({message: "登录失效，请重新登录"});
    return false;
  }

  if (isExpires()) {
    NProgress.set(0.3);
    console.log("token expires");
    // 过期 -- refresh
    try {
      await adminRefreshToken();
    } catch (e) {
      console.log("refresh token failed");
    } finally {
      NProgress.set(0.6);
    }
  }
  return true;
}

function toLogin(next) {
  clear();
  next({
    path: "/login",
  });
}

function to401(next) {
  next({
    path: "/401",
  });
}


export default router;
