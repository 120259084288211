<template>
  <div class="select-disabled-input">
    <el-select v-model="from.data.select" @change="onSelectChange">
      <el-option :key="0" :value="0" label="禁用"/>
      <el-option :key="1" :value="1" label="启用"/>
    </el-select>
    <el-input
        :type="type"
        :rows="rows"
        @input="onInputChange"
        :placeholder="placeholder"
        :disabled="from.data.select === 0"
        v-model="from.data.value"/>
  </div>
</template>

<script>

import {reactive, watchEffect} from 'vue'

export default {
  props: {
    modelValue: String,
    placeholder: String,
    type: String,
    rows: Number,
  },
  setup(props, context) {
    const from = reactive({
      data: {
        select: 0,
        value: ''
      }
    })

    watchEffect(() => {
      if (props.modelValue === 0 || props.modelValue) {
        from.data.value = props.modelValue
      }
    })

    const onSelectChange = (val) => {
      if (val === 0) {
        context.emit('update:modelValue', null)
        context.emit('onChange', null)
      } else {
        context.emit('update:modelValue', from.data.value)
        context.emit('onChange', from.data.value)
      }
    }

    const onInputChange = (value) => {
      from.data.value = value
      context.emit('update:modelValue', value)
      context.emit('onChange', value)
    }

    return {
      from,
      onInputChange,
      onSelectChange,
    }
  }
}
</script>
<style lang="scss" scoped>
.select-disabled-input {
  display: flex;
  width: 310px;

  ::v-deep .el-select .el-input__inner {
    width: 100px;
    border-right: none;
  }

  ::v-deep .el-textarea__inner {
    border-radius: 0;
    width: 210px;
  }

  ::v-deep .el-input__inner, {
    border-radius: 0;
    width: 210px;
  }

}
</style>
